<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
		</div>
		<div class="content main">
			<div class="noticeLeft">
				<div :class="viewShow ? 'isaActive' : ''" @click="newsEvt(1)">新闻公告</div>
				<div v-if="isLogin" :class="!viewShow ? 'isaActive' : ''" @click="trainEvt(2)">培训信息</div>
			</div>
			<div class="noticeRight">
				<h3>{{item.title}}</h3>
				<p class="p">
					<img src="../../assets/index/time.png" alt="">
					{{item.createTime}}
				</p>
				<p v-for='(e,index) in item.content' :key='index'>{{e}}</p>
			</div>

		</div>

		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "通知公告", "通知公告详情"],
				imgList: [{
						url: ''
					},
					{
						url: '1'
					},
					{
						url: '2'
					},
				],
				viewShow: true,
				cardList: [{
						id: 1
					},
					{
						id: 2
					},
					{
						id: 3
					},
					{
						id: 4
					}
				],
				item: {}
			}
		},
		created() {
			this.item = JSON.parse(localStorage.getItem('notice'))
			this.item.content = this.item.content.split('\n');

			if (this.item.type === '1') {
				this.viewShow = true
			} else {
				this.viewShow = false
			}
		},
		computed: {
			...mapState(['isLogin']),
		},
		methods: {
			//新闻公告
			newsEvt(index) {
				this.$router.push({
					path: '/noticeCenter',
					query: {
						status: true,
						index
					}
				})
			},
			//培训信息
			trainEvt(index) {
				this.$router.push({
					path: '/noticeCenter',
					query: {
						status: false,
						index
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: auto;
	}

	/deep/.el-carousel__button {
		background: #E51E0B;
	}

	.content {
		display: flex;
		justify-content: space-between;
	}

	.noticeLeft {
		width: 246px;
		height: 150px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);

		div {
			width: 246px;
			height: 75px;
			background: #FFFFFF;
			text-align: center;
			line-height: 75px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			cursor: pointer;
			box-sizing: border-box;
			border-left: 6px solid #fff;
		}

		.isaActive {
			color: #E51E0B;
			border-left: 6px solid #E51E0B;
			box-sizing: border-box;
		}
	}

	.noticeRight {
		width: 1144px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		padding: 50px 140px;
		margin-bottom: 50px;

		h3 {
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			text-align: center;
		}

		p {
			text-indent: 2em;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
			margin-bottom: 10px;
		}

		.p {
			text-align: center;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #999999;
			margin: 25px 0;

			img {
				height: 14px;
				width: 14px;
			}
		}

		div {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 2;
			text-indent: 30px;
		}

		.pre {
			white-space: pre-wrap;
			word-break: break-all;
			display: inline-block;
			text-indent: 2em;
		}

	}

	/deep/.el-pagination {
		text-align: center;
		margin: 30px 0;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #E51E0B;
	}
</style>
